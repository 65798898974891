.ModalWindow{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.ModalContent{
    width: 80%;
    height: 100vh;
    z-index: 15;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalImage{
    width: 100%;
    height: auto;
    margin-top: 10px;
}