.LoginField{
    width: 100%;
    height: 100vh;
    background-color: aliceblue;
    padding-top: 180px;

}

.LoginForm{
    width: 25%;
    height: 640px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid #777777;
    border-radius: 20px;
    margin: 0 auto;
    box-shadow: 0px 0px 15px -5px #777777;
}

.Title{
    text-align: center;
    border-bottom: 1px solid #777777;
    margin: 0 10px;
    margin-bottom: 100px;
}

.ErrorMessage{
    color: red;
    font-size: 12px;
    margin-top: 0px;
}

.MCIDForm{
    width: 80%;
    margin: 0 auto;
}
.MCIDForm input{
    width: 95%;
    height: 30px;
    border-radius: 50px;
    padding-left: 10px;
    border: 1px solid #777777;
}

.PassForm{
    width: 80%;
    margin: 0 auto;
}
.PassForm input{
    width: 95%;
    height: 30px;
    border-radius: 50px;
    padding-left: 10px;
    border: 1px solid #777777;
}

.CookieForm{
    width: 80%;
    margin: 0 auto;
    display: flex;
}
.CookieForm label{
    font-size: 12px;
}

.LoginField input:focus{
    outline: none;
}

.SubmitForm{
    width: 80%;
    margin: 0 auto;
    padding: 10px 0;
}

.SubmitForm input{
    width: 100%;
    height: 32px;
    background-color: aliceblue;
    border: 1px solid #777777;
    border-radius: 5px;
    transition: all .2s ease;
}

.SubmitForm input:hover{
    background-color:#d8e9f8;
}

@media screen and (max-width: 480px) {
    .LoginField{
        width: 100vw;
        height: 100vh;
        background-color: aliceblue;
        padding-top: 180px;
    
    }
    
    .LoginForm{
        width: 90%;
        height: 640px;
        background-color: rgba(255, 255, 255, 0.7);
        border: 1px solid #777777;
        border-radius: 20px;
        margin: 0 auto;
        box-shadow: 0px 0px 15px -5px #777777;
    }
}