.PlayerSearchField {
    width: 100%;
    height: auto;
    padding-top: 140px;
    background-color: rgb(210, 228, 245);
}

.PlayerField {
    width: 100%;
    height: auto;
    padding-top: 140px;
    background-color: rgb(210, 228, 245);
    padding-bottom: 100px;
}

.PlayerSearchContainer {
    width: 80%;
    height: 100vh;
    margin: 0 auto;
    margin-top: -120px;
    background-color: rgb(255, 255, 255);
    border-left: 1px solid #777777;
    border-right: 1px solid #777777;
}

.PlayerSearchTitle {
    padding-top: 120px;
    font-size: 48px;
    text-align: center;
    font-weight: 700;
}

.SearchForm {
    width: 80%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.SearchInput {
    width: 50%;
    height: 30px;
    border-radius: 20px;
    border: 1.5px solid rgb(179, 179, 179);
    transition: all .2s ease;
    padding-left: 15px;
}

.SearchInput:focus {
    width: 60%;
    height: 30px;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(33, 150, 243) inset;
}

.ResultField {
    width: 95%;
    height: auto;
    margin: 0 auto;
    border-top: 1px dotted #777777;
}

.PlayerListField {
    padding: 20px;
    height: auto;
}

.ListContainer {
    width: 100%;
}

.PlayerLine {
    width: 100%;
    height: 80px;
    border-bottom: 1px dotted #777;
    display: flex;
    align-items: center;
    margin-top: 15px;
    position: relative;
}

.ListSkin {
    padding: 5px;
}

.ListSkin img {
    width: 64px;
}

.ListMCID {
    font-size: 22px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.ListMCID p {
    transition: all .2s ease;
    color: #919090;
}

.ListMCID p:hover {
    letter-spacing: 1px;
    cursor: pointer;
}

.ListLogin {
    text-align: right;
    position: absolute;
    right: 0;
    font-size: 14px;
}

.ListOnline {
    color: rgb(128, 209, 6);
}

.ListOffline {
    color: #9b9898;
}

.Profile {
    width: 50%;
    margin: 0 auto;
    height: 400px;
    background-color: #ffffff70;
    border-radius: 20px;
    border: 2px solid #e0dcdc;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 15px -5px #777777;

}

.PlayerProfile {
    width: 78%;
    color: rgb(114, 114, 114);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.MCID {
    width: 100%;
    border: 2px dotted rgb(114, 114, 114);
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.McidField {
    width: 100%;
    border-top: 1px solid rgb(114, 114, 114);
    white-space: pre-wrap;
    margin-top: 10px;
    padding-top: 10px;
    margin-left: 5px;
    font-size: 14px;
}

.UUID {
    width: 100%;
    border: 2px dotted rgb(114, 114, 114);
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.UuidField {
    width: 100%;
    border-top: 1px solid rgb(114, 114, 114);
    white-space: pre-wrap;
    margin-top: 5px;
    padding-top: 10px;
    margin-left: 5px;
    font-size: 14px;

}

.Message {
    width: 100%;
    border: 2px dotted rgb(114, 114, 114);
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.MessageField {
    width: 100%;
    border-top: 1px solid rgb(114, 114, 114);
    white-space: pre-wrap;
    height: 100px;
    margin-top: 10px;
    padding-top: 10px;
    margin-left: 5px;
    overflow-y: auto;
    font-size: 14px;
}

.StatsField {
    width: 75%;
    margin: 0 auto;
    height: 800px;
    background-color: #ffffff70;
    border-radius: 20px;
    border: 2px solid #e0dcdc;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: normal;
    box-shadow: 0px 0px 15px -5px #777777;
    margin-top: 60px;
    color: rgb(114, 114, 114);

}

.StatsTitle {
    width: 100%;
    height: 70px;
    text-align: center;
    font-size: 36px;
    border-bottom: 1px solid #c7c0c0;
}

.StatsContent {
    width: 100%;
    height: 85%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-y: auto;
    margin-top: 10px;
}

.StatsChildrenField {
    width: 30%;
    height: 240px;
    margin: 20px;
    border-radius: 10px;
    border: 2px solid #e0dcdc;
    box-shadow: 0px 0px 15px -5px #777777;
    background-color: rgba(211, 212, 212, 0.24);
}

.StatsChildrenTitle {
    width: 90%;
    font-weight: 700;
    text-align: center;
    padding-top: 10px;
    margin: 0 auto;
    padding-bottom: 10px;
    border-bottom: 1px solid #777;
}

.StatsChildrenContent {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 20px;
    justify-content: space-between;
}

@media screen and (max-width: 480px) {
    .PlayerSearchField {
        width: 100%;
        height: auto;
        padding-top: 140px;
        background-color: rgb(210, 228, 245);
    }

    .PlayerField {
        width: 100%;
        height: auto;
        padding-top: 140px;
        background-color: rgb(210, 228, 245);
        padding-bottom: 100px;
    }

    .PlayerSearchContainer {
        width: 90%;
        height: 100vh;
        margin: 0 auto;
        margin-top: -120px;
        background-color: rgb(255, 255, 255);
        border-left: 1px solid #777777;
        border-right: 1px solid #777777;
    }

    .PlayerSearchTitle {
        padding-top: 120px;
        font-size: 36px;
        text-align: center;
        font-weight: 700;
    }

    .SearchForm {
        width: 90%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .SearchInput {
        width: 80%;
        height: 30px;
        border-radius: 20px;
        border: 1.5px solid rgb(179, 179, 179);
        transition: all .2s ease;
        padding-left: 15px;
        font-size: 16px;
        transform: scale(0.8);
    }

    .SearchInput:focus {
        width: 90%;
        height: 30px;
        outline: 0;
        box-shadow: 0 0 0 2px rgb(33, 150, 243) inset;
        font-size: 16px;
        transform: scale(0.8);
    }

    .ResultField {
        width: 95%;
        height: auto;
        margin: 0 auto;
        border-top: 1px dotted #777777;
    }

    .PlayerListField {
        padding: 5px;
        height: auto;
    }

    .ListContainer {
        width: 100%;
    }

    .PlayerLine {
        width: 100%;
        height: 80px;
        border-bottom: 1px dotted #777;
        display: flex;
        align-items: center;
        margin-top: 15px;
        position: relative;
    }

    .ListSkin {
        padding: 5px;
    }

    .ListSkin img {
        width: 32px;
    }

    .ListMCID {
        font-size: 14px;
        height: 40px;
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    .ListMCID p {
        transition: all .2s ease;
        color: #919090;
    }

    .ListMCID p:hover {
        letter-spacing: 1px;
        cursor: pointer;
    }

    .ListLogin {
        text-align: right;
        position: absolute;
        right: 0;
        font-size: 10px;
    }

    .ListOnline {
        color: rgb(128, 209, 6);
    }

    .ListOffline {
        color: #9b9898;
    }



    .PlayerProfile {
        width: 90%;
        color: rgb(114, 114, 114);
        display: block;
        flex-wrap:nowrap;
        align-items: center;
        justify-content: center;
    }

    .Profile {
        width: 80%;
        margin: 0 auto;
        height: auto;
        background-color: #ffffff70;
        border-radius: 20px;
        border: 2px solid #e0dcdc;
        padding: 20px;
        display: block;
        justify-content: left;
        box-shadow: 0px 0px 15px -5px #777777;
        position: relative;

    }
    .Profile img{
        width: 40%;
        position: absolute;
        margin-bottom: 300px;
        left: 50%;
        transform: translate(-50%);
    }
    .MCID {
        margin-top: 350px;
        width: 100%;
        border: 2px dotted rgb(114, 114, 114);
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;
    }

    .McidField {
        width: 100%;
        border-top: 1px solid rgb(114, 114, 114);
        white-space: pre-wrap;
        margin-top: 10px;
        padding-top: 10px;
        margin-left: 5px;
        font-size: 14px;
        
    }

    .UUID {
        width: 100%;
        border: 2px dotted rgb(114, 114, 114);
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;
    }

    .UuidField {
        width: 100%;
        border-top: 1px solid rgb(114, 114, 114);
        white-space: pre-wrap;
        margin-top: 5px;
        padding-top: 10px;
        margin-left: 5px;
        font-size: 14px;

    }

    .Message {
        width: 100%;
        border: 2px dotted rgb(114, 114, 114);
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .MessageField {
        width: 100%;
        border-top: 1px solid rgb(114, 114, 114);
        white-space: pre-wrap;
        height: 100px;
        margin-top: 10px;
        padding-top: 10px;
        margin-left: 5px;
        overflow-y: auto;
        font-size: 14px;
    }

    .StatsField {
        width: 80%;
        margin: 0 auto;
        height: 350px;
        background-color: #ffffff70;
        border-radius: 20px;
        border: 2px solid #e0dcdc;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: normal;
        box-shadow: 0px 0px 15px -5px #777777;
        margin-top: 60px;
        color: rgb(114, 114, 114);

    }

    .StatsTitle {
        width: 100%;
        height: 70px;
        text-align: center;
        font-size: 30px;
        border-bottom: 1px solid #c7c0c0;
    }

    .StatsContent {
        width: 100%;
        height: 85%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow-y: auto;
        margin-top: 10px;
    }

    .StatsChildrenField {
        width: 100%;
        height: 240px;
        margin: 30px 0 20px 0;

        border-radius: 10px;
        border: 2px solid #e0dcdc;
        box-shadow: 0px 0px 15px -5px #777777;
        background-color: rgba(211, 212, 212, 0.24);
    }

    .StatsChildrenTitle {
        width: 90%;
        font-weight: 700;
        text-align: center;
        padding-top: 10px;
        margin: 0 auto;
        padding-bottom: 10px;
        border-bottom: 1px solid #777;
    }

    .StatsChildrenContent {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        margin-top: 20px;
        justify-content: space-between;
    }
}