.RankingPartsField{
    border: 1px solid rgb(219, 214, 214);
    height: auto;
}

.RankingTitle{
    text-align: center;
    padding: 10px;
    background-color: rgb(248, 194, 124);
    color: #928d8d;
    font-weight: 700;
}

.RankingContent{
    display: flex;
    border-bottom: 1px solid rgb(189, 189, 189);
    font-size: 14px;
    background-color: rgb(248, 248, 248);
}

.RankingContent:nth-child(even){
    background-color: rgb(255, 255, 255);
}
.RankingContent:nth-child(odd){
    background-color: rgb(248, 248, 248);
}

.RankingContent:last-child{
    border: 0;
}

.Rank{
    border-right: 1px solid #c7c6c6;
    padding: 10px;
    width: 8%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.MCID{
    padding: 10px;
    width: 50%;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.MCID img{
    width: 24px;
    margin-right: 8px;
}

.Point{
    display: flex;
    align-items: center;
    padding: 10px;
    text-align: right;
    width: 20%;
    justify-content: right;
}