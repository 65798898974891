.StaffField {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding-top: 140px;
    align-items: center;
    background-color:  rgb(210, 228, 245);
    justify-content: center;
}

.StaffCard {
    width: calc(100% / 3 - 250px);
    height: 680px;
    justify-content: space-around;
    background-color: rgba(209, 203, 203, 0.5);
    margin: 10px;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    border: 2px solid #e2ece4;
    box-shadow: 0px 0px 15px -5px #777777;
}

.Skin {
    padding: 10px;
    border-bottom: 1px dotted #ece2e2;
}

.Skin img {
    margin-left: 25%;
    max-height: 405px;
}

.StaffInfo {
    margin-top: 20px;
    margin-left: 5px;
    margin-bottom: 20px;
}

.Name{
    font-size: 18px;
    font-weight: 700;
    color: #fffcfc;
    text-shadow: 1px 2.2px #353434;
}

.MCID {
    font-size: 24px;
    font-weight: 700;
    color: #fffcfc;
    text-shadow: 1px 2.2px #353434 ;
}

.Post {
    font-size: 18px;
    color: rgb(255, 252, 67);
    text-shadow: 1px 2px #a7a5a5 ;
}

.SNS {
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Message {
    color: #ffffff;
    font-size: 16px;
    text-shadow: 1px 2.2px #353434 ;
}

@media screen and (max-width: 480px) {
    .StaffField {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        padding-top: 140px;
        align-items: center;
        background-color:  rgb(210, 228, 245);
        justify-content: center;
    }
    
    .StaffCard {
        width: 80%;
        height: auto;
        max-height: 600px;
        justify-content: space-around;
        background-color: rgba(209, 203, 203, 0.5);
        margin: 10px;
        align-items: center;
        border-radius: 10px;
        padding: 10px;
        border: 2px solid #e2ece4;
        box-shadow: 0px 0px 15px -5px #777777;
    }
    
    .Skin {
        padding: 10px;
        border-bottom: 1px dotted #ece2e2;
    }
    
    .Skin img {
        margin-left: 25%;
        width: 50%;
    }
    
    .StaffInfo {
        margin-top: 20px;
        margin-left: 5px;
        margin-bottom: 20px;
    }
    
    .Name{
        font-size: 18px;
        font-weight: 700;
        color: #fffcfc;
        text-shadow: 1px 2.2px #353434;
    }
    
    .MCID {
        font-size: 24px;
        font-weight: 700;
        color: #fffcfc;
        text-shadow: 1px 2.2px #353434 ;
    }
    
    .Post {
        font-size: 18px;
        color: rgb(255, 252, 67);
        text-shadow: 1px 2px #a7a5a5 ;
    }
    
    .SNS {
        display: flex;
        align-items: center;
        height: 30px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    .Message {
        color: #ffffff;
        font-size: 16px;
        text-shadow: 1px 2.2px #353434 ;
    }
}