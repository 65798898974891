.GameBody {
    width: 100%;
    height: auto;
    background-color: rgb(210, 228, 245);
    padding-top: 140px;
}

.GameField {
    width: 80%;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin: 0 auto;
    margin-top: -120px;
    padding-top: 120px;
    padding-bottom: 25px;
    color: #777;
}

.GameTitle {
    font-size: 40px;
    text-align: center;
    margin: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #777;

}

.GameContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    height: auto;
}

.GameParts {
    width: 300px;
    border-radius: 10px;
    margin-top: 40px;
    background-color: rgba(246, 247, 250, 0.541);
    border: 2px solid rgba(160, 157, 157, 0.397);
    box-shadow: 0 0 15px -5px #777777;
    padding: 15px;
    margin-left: 30px;

}

.GameParts:hover{
    cursor: pointer;
}

.PartsTitle {
    font-size: 20px;
    text-align: center;
    padding-bottom: 5px;
}

.PartsLinks {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.Link {
    display: flex;
    align-items: center;
    font-size: 16px;
    transition: all .2s ease;
}

.Link:hover {
    font-size: 18px;
    cursor: pointer;

}

.PartsMemo{
    text-align: center;
    height: 100px;
    font-size: 14px;
    padding-top: 20px;
}

table.framed{
    border-spacing: 0;
}
table.framed th,
table.framed td {
    border: black 1px solid;
    border-collapse: collapse;
    padding: 0 4px;
}

code {
    background-color: #eee;
    color: #111;
    padding: 0 2px;
}

table#tbl_levels ul {
    list-style: none;
    padding-left: 0;
    margin: 5px;
}

@media screen and (max-width: 480px) {
    .GameBody {
        width: 100%;
        height: auto;
        background-color: rgb(210, 228, 245);
        padding-top: 140px;
    }
    
    .GameField {
        width: 90%;
        height: auto;
        background-color: rgb(255, 255, 255);
        margin: 0 auto;
        margin-top: -120px;
        padding-top: 120px;
        color: #777;
    }
    
    .GameTitle {
        font-size: 28px;
        text-align: center;
        margin: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #777;
    
    }
    
    .GameContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        padding-left: 0;
        padding-right: 0;
        padding-top: 40px;
        height: auto;
    }
    
    .GameParts {
        width: 80%;
        border-radius: 10px;
        margin-top: 40px;
        background-color: rgba(246, 247, 250, 0.541);
        border: 2px solid rgba(160, 157, 157, 0.397);
        box-shadow: 0 0 15px -5px #777777;
        padding: 15px;
        margin-left: 16px;
    
    }
    
    .GameParts:hover{
        cursor: pointer;
    }
    
    .PartsTitle {
        font-size: 20px;
        text-align: center;
        padding-bottom: 5px;
    }
    
    .PartsLinks {
        width: 100%;
        height: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    
    .Link {
        display: flex;
        align-items: center;
        font-size: 16px;
        transition: all .2s ease;
    }
    
    .Link:hover {
        font-size: 18px;
        cursor: pointer;
    
    }
    
    .PartsMemo{
        text-align: center;
        height: 100px;
        font-size: 12px;
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 20px;
    }
}